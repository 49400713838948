import transfer from "./transfer";
import transferWAREHOUSEtoVAN from "./transferWAREHOUSEtoVAN";
import transferVANtoVAN from "./transferVANtoVAN";
import transferDIRECTtoVAN from "./transferDIRECTtoVAN";
import transferee from "./transferee";
import transferProduct from "./transferProduct";

export default {
    transfer,
    transferWAREHOUSEtoVAN,
    transferDIRECTtoVAN,
    transferVANtoVAN,
    transferee,
    transferProduct
};
