<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">เพิ่มรายการตัดสินค้าทั้งหมด</h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <!-- <div class="row d-flex justify-content-center mb-4">
              <label class="col-sm-8 text-start">เลขที่เอกสาร</label>
              <div class="col-sm-8">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div> -->
            <div class="row d-flex justify-content-center text-start mb-4">
              <label for="input1  form-label" class="col-sm-8">{{
                $t("findproduct")
              }}</label>
              <div class="col-sm-8">
                <input
                  v-model="searchProduct"
                  type="text"
                  class="form-control"
                  placeholder="ค้นหา Item code / ชื่อสินค้า
                      "
                  aria-describedby="basic-addon1"
                  :disabled="isView"
                  list="datalistOptions"
                />
                <datalist
                  id="datalistOptions"
                  v-if="searchListProduct.length >= 1"
                >
                  <option
                    id="datalistOptions11"
                    v-for="(item, index) in searchListProduct"
                    :key="index"
                    :value="item.code"
                  >
                    {{ item.name }}
                    <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />
                  </option>
                </datalist>
              </div>
            </div>

            <div
              class="row d-flex justify-content-center text-start mb-4"
              v-if="searchListProduct[0]"
            >
              <label class="col-sm-8">Item no</label>
              <div class="col-sm-8">
                <input
                  v-model="searchListProduct[0].code"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <div
              class="row d-flex justify-content-center text-start mb-4"
              v-if="searchListProduct[0]"
            >
              <label class="col-sm-8">Item Name</label>
              <div class="col-sm-8">
                <input
                  v-model="searchListProduct[0].name"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>

            <div
              class="row d-flex justify-content-center text-start"
              v-if="productstock && searchListProduct[0]"
            >
              <label class="col-sm-8">หน่วยสินค้า</label>
              <div class="col-sm-8">
                <select class="form-select" v-model="form.productUnitId">
                  <option
                    v-for="item in productstock.productPrices"
                    :key="item"
                    :value="item.productUnit"
                  >
                    {{ item.productUnit.code }} | {{ item.productUnit.name }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="row d-flex justify-content-center text-start"
              v-if="productstock && searchListProduct[0]"
            >
              <label class="col-sm-8">จำนวน</label>
              <div class="col-sm-8">
                <input
                  v-model="stockAmt"
                  type="number"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
          </div>

          <div class="modal-footer d-flex justify-content-start">
            <hr class="pt-0 mt-0" style="color: LightGrey" />

            <footer>
              <div class="card-toolbar">
                <button
                  @click="submit()"
                  type="button"
                  class="btn btn-primary me-3"
                  data-bs-dismiss="modal"
                >
                  บันทึก
                </button>
                <button
                  @click="close"
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import whApi from "@/api/warehouse/";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import warehouse from "@/api/warehouse/warehouse";
export default {
  props: {
    dialogAddItem: Boolean,
    warehouseId: String,
  },
  data: () => ({
    isSubmit: false,
    searchProduct: "",
    searchListProduct: [],
    productstock: [],
    stockAmt: "",

    form: {},
  }),
  watch: {
    searchProduct(val) {
      console.log("คำค้นหา", val);
      this.addProductItems(val);
    },
    dialogAddItem(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
      }
    },
    searchListProduct(val) {
      if (val.length == 1) {
        this.getProductBywarehouseId();
      }
    },
  },
  // productId {{ searchListProduct[0].id }}
  // warehouseId {{ warehouseId }}

  methods: {
    async addProductItems(searchProduct) {
      let responseData = [];
      try {
        responseData = await whApi.product.search({ search: searchProduct });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.searchListProduct = responseData.data;
        console.log("this.searchListProduct", this.searchListProduct);
      }
    },
    async getProductBywarehouseId() {
      let responseData = [];
      try {
        responseData = await whApi.product.getOne(
          // this.warehouseId,
          this.searchListProduct[0].id
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.productstock = responseData.data;
        console.log("this.productstock", this.productstock);
      }
    },
    async submit() {
      let arr = this.searchListProduct[0];
      // this.searchListProduct[0].productPrices.forEach((element) => {
      //   if (element.default == true) {
      //     arr.productPrices = element;
      //   }
      // });
      let body = {
        id: this.searchListProduct[0].id,
        code: this.searchListProduct[0].code,
        productUnitId: this.form.productUnitId.id,
        name: this.searchListProduct[0].name,
        // to_warehouse_id: this.warehouseId,
        // toWarehouseId:
        stockAmt: this.stockAmt,
        unit: this.form.productUnitId.name,
        // productTransfer:[],
        // remark:
        // transactionNumber:
      };
      this.$emit("addProduct", body);
      this.$emit("closeDialogAddItem");
    },
    close() {
      this.$emit("closeDialogAddItem");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
