import axios from "../instance";

export default {
    getAll: (companyId) => {
        if (companyId) {
            return axios.api.get(`/transactionTransfers?companyId=${companyId}`).then((response) => response.data);
        } else {
            return axios.api.get("/transactionTransfers").then((response) => response.data);
        }
    },
    getAllbyStatus: (status) => {
        return axios.api.get(`/transactionTransfers?status=${status}`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/transactionTransfers/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post(`/transactionTransfers/search`, body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/transactionTransfers", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/transactionTransfers/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/transactionTransfers/${id}`).then((response) => response.data);
    },
    // /warehouseStocks/addStockTransaction
    addStockTransaction: (body) => {
        return axios.api.post("/warehouseStocks/addStockTransaction", body).then((response) => response.data);
    },
    getAllStockTransactions: (companyId) => {
        if (companyId) {
            return axios.api.get(`/warehouseStocks/getAllStockTransactions?companyId=${companyId}`).then((response) => response.data);
        } else {
            return axios.api.get(`/warehouseStocks/getAllStockTransactions`).then((response) => response.data);
        }

    },
    getOneStockTransaction: (id) => {
        return axios.api.get(`/warehouseStocks/getOneStockTransaction/${id}`).then((response) => response.data);
    },
    getAllAdjustStockTransactions: (companyId) => {
        if (companyId) {
            return axios.api.get(`/warehouseStocks/getAllAdjustStockTransactions?companyId=${companyId}`).then((response) => response.data);
        } else {
            return axios.api.get(`/warehouseStocks/getAllAdjustStockTransactions`).then((response) => response.data);
        }

    },
    getOneAdjustStockTransaction: (id) => {
        return axios.api.get(`/warehouseStocks/getOneAdjustStockTransaction/${id}`).then((response) => response.data);
    },
    adjustStockTransaction: (body) => {
        return axios.api.post("/warehouseStocks/adjustStockTransaction", body).then((response) => response.data);
    }
};
