import axios from "../instance";

export default {
    getAll: (companyId) => {
        return axios.api.get(`/vanTransactionTransfers?isDirect=1&companyId=${companyId}`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/vanTransactionTransfers/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post(`/vanTransactionTransfers/search`, body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/vanTransactionTransfers/createDirectToVan", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/vanTransactionTransfers/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/vanTransactionTransfers/${id}`).then((response) => response.data);
    }
};
