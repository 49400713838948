import axios from "../instance";

export default {
    getAll: () => {
        return axios.api.get("/vanToVanTransactionTransfers").then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/vanToVanTransactionTransfers/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post(`/vanToVanTransactionTransfers/search`, body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/vanToVanTransactionTransfers", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/vanToVanTransactionTransfers/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/vanToVanTransactionTransfers/${id}`).then((response) => response.data);
    }
};
